import React, { Component } from "react"
import { Helmet } from "react-helmet"
import { ContactForm, Layout } from "../../../components"
import {
  BlockImageTextHalf,
  Cubes,
  HalfWidthBlock,
  Medailon,
  Media,
  PaddingWrapper,
  ReferenceBanner,
  ReferenceBannerContent,
  ReferenceContainer,
  TitleH2,
} from "@igloonet-web/shared-ui"

import Logo from "../../../images/reference/panvicky/logo-panvicky.png"
import Top from "../../../images/reference/panvicky/top.jpg"
import Wireframe from "../../../images/reference/panvicky/wireframe1.png"
import Mid from "../../../images/reference/panvicky/timeline.png"
import Design from "../../../images/reference/panvicky/design.png"
import Hak from "../../../images/team/mini/levejhak.jpg"
import Sobol from "../../../images/team/mini/sobol.jpg"
import MobileScreen from "../../../images/reference/panvicky/mobile-screen.png"
import DesktopScreen from "../../../images/reference/panvicky/desktop-screen.png"

class Panvicky extends Component {
  constructor({ props }) {
    super(props)
  }

  render() {
    const pozadi = {
      backgroundImage: `linear-gradient(rgba(0,0,0,0.9),rgba(0,0,0,0.7)),url(${Top})`,
    }

    return (
      <Layout>
        <Helmet>
          <title>Nový e-shop pro Panvicky.cz | igloonet</title>
          <meta
            name="description"
            content="Reference vytvoření nového e-shopu pro panvicky.cz, specialistu na kuchyňské nádobí."
          />
        </Helmet>
        <ReferenceBanner
          styles={pozadi}
          logo={Logo}
          alt="logo"
          extodkaz="//panvicky.cz/"
          heading="Vytvoření e-shopu pro www.panvicky.cz"
          odstavec="červen 2017 - říjen 2021"
        >
          <ReferenceBannerContent mainText="Prvotním zadáním bylo vytvořit nový e-shop, přiblížit se zákazníkovi a nepřímo podpořit navýšení obratu obchodu." />
        </ReferenceBanner>

        <ReferenceContainer>
          <Media
            iPhone={{
              backgroundSrc: MobileScreen,
              backgroundAlt: "",
            }}
            laptop={{
              backgroundSrc: DesktopScreen,
              backgroundAlt: "",
            }}
          />
          <PaddingWrapper>
            <HalfWidthBlock>
              <TitleH2>Cíle e-shopu</TitleH2>
              <ul>
                <li>Zjednodušit proces nákupu.</li>
                <li>
                  Zpřehlednit nabídku produktů pro jednotlivé cílové skupiny
                  zákazníků.
                </li>
                <li>Jednoduchá správa e-shopu ze strany klienta.</li>
              </ul>
            </HalfWidthBlock>{" "}
          </PaddingWrapper>

          <PaddingWrapper>
            <BlockImageTextHalf
              className="d-md-none"
              image={Wireframe}
              alt={"ALT"}
              right
              hideImageOnSmall
            >
              <TitleH2>Analýza a wireframy</TitleH2>

              <p>
                Na základě analýzy konkurence, základního uživatelského výzkumu
                a konzultace s klientem, jsme vytvořili wireframy pro nový
                e-shop.
              </p>
              <p>
                Graficky kvalitně zpracované wireframy, tedy skicy webu s
                rozložením funkčních prvků, nám umožňují zapojit klienta do
                procesu výroby webové aplikace.
              </p>
              <Cubes
                data={[
                  {
                    description: "Analýza konkurence",
                    number: "+",
                  },
                  {
                    description: "Uživatelský výzkum",
                    number: "+",
                  },
                  {
                    description: "Hodiny nad wireframy",
                    number: "+",
                  },
                ]}
              />
            </BlockImageTextHalf>
          </PaddingWrapper>
        </ReferenceContainer>

        <img
          src={Mid}
          alt=""
          className="img-fluid mb-4 mx-auto d-none d-md-flex"
          loading="lazy"
        />

        <ReferenceContainer>
          <Medailon
            image={Hak}
            alt=""
            text="Mapu obsahu a wireframy jsme tvořili na základně získaných dat z analytické části.
            Při návrhu designu tedy nemyslíme jen na estetickou část, ale především na tu funkční."
            name="Petr Pitucha"
            position="UX a grafika"
          />

          <PaddingWrapper>
            <BlockImageTextHalf
              className="d-md-none"
              image={Design}
              alt={"ALT"}
              right
              hideImageOnSmall
            >
              <TitleH2>Design</TitleH2>

              <p>
                V igloo máme rádi vyvážení vizuálně <b>atraktivního designu</b>{" "}
                s uživatelskou přívětivostí.
              </p>
              <p>
                Grafika musí návštěvníka zaujmout neotřelým designem, ale
                nemůžeme zapomenout na <b>UX</b> a důvody, proč vlastně na
                stránky přišel.
              </p>
            </BlockImageTextHalf>
          </PaddingWrapper>

          <PaddingWrapper>
            <Medailon
              image={Sobol}
              alt=""
              text="Klient si vybral pro realizaci svého e-shopu igloonet kvůli
            spojení vývoje a marketingu. Hledal partnera se širokým záběrem,
            který pro něj nejen vyvine e-shop, ale zároveň pomůže s prodejem
            jeho produktů."
              name="Jan Nobilis"
              position="Vedení projektu"
            />
          </PaddingWrapper>

          <PaddingWrapper>
            <HalfWidthBlock>
              <TitleH2>Shrnutí</TitleH2>
              <p>
                Na základě analýz jsme vytvořili jasně zaměřený e-shop s velkým
                důrazem na zákazníka. Na práce na vývoji navázali kolegové z
                marketingu a pomohli Pánvičkám vytvořit marketingovou strategii,
                v rámci které teď řešíme PPC a mailingové kampaně. Aktuálně se
                plánujeme zaměřit na posilování značky a přípravy na hlavní
                sezónu.
              </p>
            </HalfWidthBlock>
          </PaddingWrapper>
        </ReferenceContainer>

        <ContactForm contact="sobol" />
      </Layout>
    )
  }
}

export default Panvicky
